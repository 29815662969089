import { useState } from "react"
import { Input } from "../../components/form/input"
import { Header } from "../../components/header/Header"
import styles from "./signup.module.css"
import { Button } from "../../components/button/Button"
import classNames from "classnames"
import { finishSignup } from "../../fetch/users"
import { useLocation, useParams } from "wouter"

export const SignupComponent = () => {
  const { id } = useParams()
  const [, setLocation] = useLocation()
  const [formValues, setValue] = useState({
    name: "",
    username: "",
    surname: "",
    password: "",
    repeatPassword: "",
  })
  const [errors, setErrors] = useState<Record<string, boolean>>({})

  const handleSignUp = async () => {
    if (!id) {
      // TODO: should assert? impossible case
      return
    }

    const { password, repeatPassword, name, surname, username } = formValues
    const nextErrors: Record<string, boolean> = {}
    if (!password || password !== repeatPassword) {
      nextErrors["password"] = true
      nextErrors["repeatPassword"] = true
      // TODO:invalid password case
    } else {
      if (
        !/[A-Z]/.test(password) ||
        !/[0-9]/.test(password) ||
        !/[a-z]/.test(password) ||
        password.length < 8
      ) {
        nextErrors["password"] = true
      }
    }

    if (!username) {
      nextErrors["username"] = true
    }

    if (/[^a-z0-9\.\-]/.test(username)) {
      nextErrors["username"] = true
    }

    if (!name) {
      nextErrors["name"] = true
    }

    if (!surname) {
      nextErrors["surname"] = true
    }

    if (Object.keys(nextErrors).length) {
      setErrors(nextErrors)
      return
    }

    try {
      const re = await finishSignup(
        { username, name: `${name} ${surname}`, password },
        id
      )

      if (!re.ok) {
        throw new Error("failed")
      }
      setLocation("/")
    } catch (err) {
      // TODO: handle error
    }
  }

  const handleInputChange = (field: string, value: string) => {
    setErrors((curr) => ({
      ...curr,
      [field]: false,
    }))

    setValue((curr) => ({ ...curr, [field]: value }))
  }
  return (
    <div>
      <Header hideMenu />

      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.root}>
          <h1 className={styles.addTitle}>Pabeigt reģistrāciju</h1>
          <div className={styles.row}>
            <Input
              label="Lietotājvārds"
              placeholder="Lietotājvārds"
              value={formValues.username}
              error={errors.username}
              onChange={(e) => handleInputChange("username", e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <Input
              label="Vārds"
              placeholder="Vārds"
              value={formValues.name}
              error={errors.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <Input
              label="Uzvārds"
              placeholder="Uzvārds"
              value={formValues.surname}
              error={errors.surname}
              onChange={(e) => handleInputChange("surname", e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <Input
              label="Parole"
              placeholder="Parole"
              value={formValues.password}
              error={errors.password}
              onChange={(e) => handleInputChange("password", e.target.value)}
              type="password"
            />
            <Input
              label="Parole atkārtoti"
              placeholder="Parole atkārtoti"
              value={formValues.repeatPassword}
              error={errors.repeatPassword}
              onChange={(e) =>
                handleInputChange("repeatPassword", e.target.value)
              }
              type="password"
            />
          </div>
          <div className={classNames(styles.row, styles.submit)}>
            <Button onClick={handleSignUp} type="submit">
              Reģistrēties
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
