import { InputHTMLAttributes } from "react"
import styles from "./input.module.css"
import classNames from "classnames"

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  error?: boolean
}
export const Input = (props: Props) => {
  const hasError = !!props.error
  return (
    <div className={styles.inputWrap}>
      <label>
        {props.label && <span>{props.label}</span>}
        <input
          className={classNames(styles.input, hasError ? styles.error : "")}
          {...props}
        />
      </label>
    </div>
  )
}

type TextAreaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
  error?: boolean
}

export const TextArea = (props: TextAreaProps) => {
  const hasError = !!props.error
  return (
    <div className={styles.inputWrap}>
      <label>
        {props.label && <span>{props.label}</span>}
        <textarea
          className={classNames(styles.input, hasError ? styles.error : "")}
          {...props}
        />
      </label>
    </div>
  )
}
