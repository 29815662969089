import { useEffect, useState } from "react"
import { useLocation } from "wouter"
import { Project } from "../../../../types"
import { StatusItem } from "../../../components/statusItem/StatusItem"
import { getUserProjects } from "../../../fetch/projects"
import styles from "./tasks.module.css"

export const Tasks = () => {
  const [, setLocation] = useLocation()
  const [projects, setProjects] = useState<
    { title: string; items: Project[] }[]
  >([])
  useEffect(() => {
    getUserProjects().then(async (res) => {
      const json = await res.json()
      setProjects(json)
    })
  }, [])

  const goToTask = (id: number) => {
    setLocation(`/dashboard/project/${id}`)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>Mani uzdevumi</div>
      {projects.map((proj) =>
        proj.items.length ? (
          <div>
            <div className={styles.heading}>{proj.title}</div>
            {proj.items.map((item) => {
              return (
                <StatusItem
                  onClick={() => goToTask(item.id)}
                  status={item.status}
                  name={item.name}
                  subTitle={`${item.subCount} Projekti`}
                />
              )
            })}
          </div>
        ) : null
      )}
    </div>
  )
}
