import { createWatchable } from "../lib/state/createWatchable"

export enum TenantFeature {
  Time = "time",
  Units = "units",
  Salary = "salary",
  Errors = "errors",
  Districts = "districts",
  Normatives = "normatives",
  StrictOrder = "strictOrder",
}

export type TenantDetails = {
  id: number
  name: string
  slug: string
  settings: {
    features: Partial<Record<TenantFeature, boolean>>
    labels: {
      Apakšprojekts: string
      Apakšprojekti: string
      Darbs: string
      Darbi: string
      Operācija: string
      Operācijas: string
      Projekts: string
      Projekti: string
    }
  }
}

export const tenantState = createWatchable<TenantDetails>({
  id: 0,
  name: "",
  slug: "",
  settings: {
    features: {},
    labels: {
      Apakšprojekts: "",
      Apakšprojekti: "",
      Darbs: "",
      Darbi: "",
      Operācija: "",
      Operācijas: "",
      Projekts: "",
      Projekti: "",
    },
  },
})
