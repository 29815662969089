import { ComponentProps, useCallback, useMemo, useState } from "react"
import { InfoBlock } from "../../../components/infoBlock/InfoBlock"
import { InfoBlockWrap } from "../../../components/infoBlock/InfoBlockWrap"
import { Table } from "../../../components/table/Table"
import { Button } from "../../../components/button/Button"
import { finishTask, getTasks } from "../../../fetch/tasks"
import { ConfirmTask } from "./ConfirmTask"
import { Task } from "../../../../types"
import { formatTimeDelta } from "../../../lib/formatTimeDelta"
import { isBitus } from "../../../../config/urls"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"
import { Link } from "wouter"
import { Filters } from "./Filters"
import { useFetchHook } from "../../../lib/hooks/fetchHook"
import { toast } from "react-toastify"

const columns: { title: string; id: keyof Task }[] = [
  { title: "Darbinieks", id: "workerName" },
  { title: isBitus ? "Sērija" : "Projekts", id: "projectName" },
  { title: isBitus ? "Iecirknis" : "Subprojekts", id: "subProjectName" },
  { title: "Laiks", id: "timeUsed" },
  // { title: "Paredzētais laiks", id: "timePredicted" },
  // { title: "Datums", id: "date" },
  { title: "Izveidots", id: "createdAt" },
  { title: "Paredzēts", id: "unitsPredicted" },
  { title: "Aprēķināts", id: "salaryPredicted" },
  { title: "Summa", id: "salary" },
  { title: "Vienības", id: "unitsUsed" },
  { title: "Piezīmes", id: "comment" },
  { title: "Apstrādāts", id: "status" },
]

type Row = ComponentProps<typeof Table>["rows"]

export const AdminTasks = () => {
  const [filters, setFilters] = useState<Record<string, any>>({})
  const { data, reload } = useFetchHook<
    { items: Task[]; info: ComponentProps<typeof InfoBlock>[] },
    Record<string, any>
  >(getTasks, { params: filters, defaultValue: { items: [], info: [] } })
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const [selectedId, setSelectedId] = useState<number | undefined>()

  const confirmTask = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
      e.preventDefault()
      e.stopPropagation()
      await finishTask(id, {
        status: 1,
      })

      toast("Uzdevums veiksmīgi apstiprināts!", { type: "success" })
      reload()
    },
    [reload],
  )

  const tableData = useMemo(() => {
    const rows: ComponentProps<typeof Table>["rows"] = []
    data?.items.forEach((row) => {
      const curr: Row[number] = { id: row.id }

      columns.forEach(({ id }) => {
        switch (id) {
          case "workerName": {
            const value = row[id]
            curr[id] =
              // <Link to={`/../employees/${row.project.id}`}>{value.name}</Link>
              value
            break
          }
          case "projectName": {
            const value = row[id]
            curr[id] = <Link to={`/../projects/${row.projectId}`}>{value}</Link>
            break
          }
          case "subProjectName": {
            const value = row[id]
            curr[id] = (
              <Link to={`/../projects/${row.projectId}/${row.subProjectId}`}>
                {value}
              </Link>
            )
            break
          }

          case "timePredicted":
          case "timeUsed": {
            const value = row[id]
            curr[id] = value ? formatTimeDelta(value) : "-"
            break
          }
          case "salary": {
            const v = row["salaryUsed"]
            curr[id] = v ? `${v / 100}€` : "-"
            break
          }
          case "salaryPredicted": {
            const v = row["salaryPredicted"]
            curr[id] = v ? `${v / 100}€` : "-"
            break
          }
          case "unitsUsed":
          case "unitsPredicted": {
            const value = row[id]
            curr[id] = value
            break
          }

          case "date": {
            const value = row[id]
            curr[id] = value
            break
          }
          case "createdAt": {
            const value = row[id]
            curr[id] = value
              ? new Intl.DateTimeFormat("lv", {
                  dateStyle: "short",
                  timeStyle: "short",
                }).format(new Date(value))
              : "-"
            break
          }
          case "comment": {
            const value = row[id]
            curr[id] = value ? (
              <span
                style={{
                  maxWidth: 100,
                  display: "block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {value}
              </span>
            ) : (
              "-"
            )
            break
          }
          case "status": {
            const value = row[id] as unknown as number
            if (value === 1) {
              curr[id] = "Apstiprināts"
              break
            }
            curr[id] = (
              <Button onClick={(e) => confirmTask(e, row.id)}>
                Apstiprināt
              </Button>
            )
            break
          }
        }
      })
      rows.push(curr)
    })
    return rows
  }, [data?.items, confirmTask])

  const handleFiltersChange = (nextFilters: Record<string, any>) => {
    setFilters(nextFilters)
  }

  const onRowClick = (id: number) => {
    setSelectedId(id)
  }

  const selectedTask = useMemo(
    () => data?.items.find((t) => t.id === selectedId),
    [data?.items, selectedId],
  )

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => {
      if (col.id === "timeUsed") {
        return features[TenantFeature.Time]
      }

      if (col.id === "unitsUsed") {
        return features[TenantFeature.Units]
      }
      if (col.id === "unitsPredicted") {
        return features[TenantFeature.Units]
      }

      if (col.id === "salary") {
        return features[TenantFeature.Salary]
      }
      if (col.id === "salaryPredicted") {
        return features[TenantFeature.Salary]
      }

      return true
    })
  }, [features])

  return (
    <>
      <Filters
        onFiltersChange={handleFiltersChange}
        availableFilters={["from", "to", "workers", "projects", "status"]}
        show={!selectedTask}
      />
      <ConfirmTask
        task={selectedTask}
        onClose={(forceReload) => {
          setSelectedId(undefined)
          if (forceReload) {
            reload()
          }
        }}
      />
      <InfoBlockWrap>
        {data?.info.map((block, i) => <InfoBlock {...block} key={i} />)}
      </InfoBlockWrap>
      <Table
        columns={filteredColumns}
        rows={tableData}
        onRowClick={(id) => onRowClick(+id)}
      />
    </>
  )
}
