import classNames from "classnames"
import { useState } from "react"
import { Button } from "../../../../../components/button/Button"
import { Input, TextArea } from "../../../../../components/form/input"
import { finishOperation } from "../../../../../fetch/tasks"
import styles from "./finish-task.module.css"
import { formatDate } from "../../../../../lib/formatDate"
import { toast } from "react-toastify"

export const FinishTask = ({
  opId,
  isOpen,
  handleClose,
  isFinishing = false,
}: {
  isOpen: boolean
  opId?: number
  handleClose: (reload?: boolean) => void
  isFinishing?: boolean
}) => {
  const [date, setDate] = useState(formatDate(new Date()))
  const [minutes, setMinutes] = useState(0)
  const [comment, setComment] = useState("")
  const [hours, setHours] = useState(0)
  const [units, setUnits] = useState(0)
  const [errors, setErrors] = useState<Record<string, boolean>>({})

  const handleSubmit = async () => {
    if (!opId) {
      return
    }
    const body = {
      date: formatDate(new Date(date)),
      timeUsed: hours * 60 + minutes,
      operationId: opId,
      unitsUsed: units,
      comment,
    }

    const hasUnitsOrTime = units || hours || minutes

    const nextErrors: Record<string, boolean> = {}
    if (!date) {
      nextErrors.date = true
      setErrors(nextErrors)
      return
    }

    if (!hasUnitsOrTime || (!hours && !minutes)) {
      nextErrors.units = true
      setErrors(nextErrors)
      toast("Jānorāda vienības vai laiks", { type: "error" })
      return
    }
    try {
      const re = await finishOperation(body)
      if (re && re.ok) {
        toast("Darbs veiksmīgi pievienots", { type: "success" })
        handleClose(true)
        setHours(0)
        setMinutes(0)
        setUnits(0)
      } else {
        throw new Error("")
      }
    } catch (err) {
      toast("Neizdevās pievienot uzdevumu", { type: "error" })
      console.log(err)
    }
  }

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value)
  }

  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }
  const onHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const next = +e.target.value
    if (isNaN(next)) {
      return
    }
    setErrors((curr) => ({ ...curr, units: false }))
    setHours(next)
  }
  const onMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const next = +e.target.value
    if (isNaN(next)) {
      return
    }
    setErrors((curr) => ({ ...curr, units: false }))
    setMinutes(next)
  }

  const onUnitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const next = +e.target.value
    if (isNaN(next)) {
      return
    }
    setErrors((curr) => ({ ...curr, units: false }))
    setUnits(next)
  }
  return (
    <div className={classNames(styles.bottomSheet, isOpen ? styles.open : "")}>
      <div className={styles.inner}>
        <div className={styles.title}>Pievienot laiku</div>
        <Input
          type="date"
          label="Datums"
          min="2024-07-07"
          onChange={onDateChange}
          value={date}
        />
        <div className={styles.inputs}>
          <Input
            label="Stundas"
            value={hours}
            error={errors.units && !hours}
            onChange={onHoursChange}
          />
          <Input
            label="Minūtes"
            value={minutes}
            error={errors.units && !minutes}
            onChange={onMinutesChange}
          />
        </div>
        <div className={styles.inputs}>
          <Input
            label="Vienības"
            value={units}
            error={errors.units && !units}
            onChange={onUnitsChange}
          />
        </div>
        <div className={styles.inputs}>
          <TextArea
            label="Piezīmes"
            value={comment}
            error={errors.units && !units}
            onChange={onCommentChange}
          />
        </div>
        <div className={styles.buttonWrap}>
          <Button onClick={handleSubmit}>Saglabāt</Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Atcelt
          </Button>
        </div>
      </div>
    </div>
  )
}
