import { FormEvent, useEffect, useState } from "react"
import { EditIcon, IconButton } from "../../../components/button/IconButton"
import { Sidepanel } from "../../../components/sidepanel/Sidepanel"
import { Input } from "../../../components/form/input"
import { Project } from "../../../../types"
import { Button } from "../../../components/button/Button"
import { updateProject } from "../../../fetch/projects"
import { toast } from "react-toastify"
import styles from "./editop.module.css"

export const EditOperation = ({
  project,
  onSave,
}: {
  project: Project
  onSave?: () => void
}) => {
  const [open, setOpen] = useState(false)

  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const [name, setName] = useState(project.name ?? "")
  const [shift, setShift] = useState(project.shift)
  const [shiftsPerDay, setShiftsPerDay] = useState(project.concurrentShifts)
  const [workers, setWorkers] = useState(project.workers)

  useEffect(() => {
    const values = {
      name,
      shift,
      shiftsPerDay,
      workers,
    }

    const nextErrors: Record<string, boolean> = {}
    Object.keys(values).forEach((key) => {
      if (values[key as keyof typeof values]) {
        nextErrors[key] = false
      }
    })
    setErrors((err) => ({ ...err, ...nextErrors }))
  }, [name, shift, shiftsPerDay, workers])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const nextErrors: Record<string, boolean> = {}
    if (!name) {
      nextErrors.name = true
    }

    if (!shift) {
      nextErrors.shift = true
    }

    if (!shiftsPerDay) {
      nextErrors.shiftsPerDay = true
    }

    if (!workers) {
      nextErrors.workers = true
    }

    if (Object.keys(nextErrors).length) {
      setErrors(nextErrors)
      return
    }

    try {
      await updateProject(project.id, {
        name,
        shift,
        concurrentShifts: shiftsPerDay,
        workers,
      })
      toast("Veiksmīgi izlabots", { type: "success" })
      onSave?.()
    } catch (err) {
      toast("Labošana nesekmīga, lūdzu mēģiniet vēlreiz", { type: "error" })
    }
  }

  const shifts = 3 * (shiftsPerDay ?? 1)
  const hours = shifts * (shift ?? 0)

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(true)
        }}
      >
        {EditIcon}
      </IconButton>
      <Sidepanel open={open} onClose={() => setOpen(false)}>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Input
              label={"Iecirkņa nosaukums"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={errors.name}
            />
            <Input
              label={"Darbinieku skaits"}
              value={workers}
              type="number"
              onChange={(e) => setWorkers(+e.target.value)}
              error={errors.workers}
            />
            <Input
              label={"Maiņas ilgums"}
              value={shift}
              type="number"
              onChange={(e) => setShift(+e.target.value)}
              error={errors.shift}
            />
            <Input
              label={"Maiņu skaits dienā"}
              type="number"
              value={shiftsPerDay}
              onChange={(e) => setShiftsPerDay(+e.target.value)}
              error={errors.shiftsPerDay}
            />
          </div>
          <div className={styles.districtOperation}>
            Kopējais pieejamais 3 dienu resurss: {shifts} maiņas jeb {hours}h
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div />
            <div style={{ display: "flex", gap: 8 }}>
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  setOpen(false)
                }}
              >
                Atcelt
              </Button>
              <Button type="submit">Saglabāt</Button>
            </div>
          </div>
        </form>
      </Sidepanel>
    </>
  )
}
