import { useEffect } from "react"
import { localFetch } from "../fetch/localFetch"
import { tenantState } from "../store/tenant"

export const FeatureProvider = () => {
  useEffect(() => {
    localFetch(`/api/tenants/current/details`)
      .then((res) => {
        return res.json()
      })
      .then((json) => {
        tenantState.update(json)
      })
  }, [])
  return null
}
