import { Link } from "wouter"
import styles from "./breadcrumbs.module.css"
import { Fragment } from "react/jsx-runtime"

type BreadCrumb = {
  title: string
  path: string
}

type Props = {
  items: BreadCrumb[]
}
export const BreadCrumbs = (props: Props) => {
  return (
    <div className={styles.breadcrumbs}>
      {props.items.map((item, i) => {
        const isLast = i === props.items.length - 1
        return (
          <Fragment key={i}>
            <Link to={item.path}>{item.title}</Link>
            <span className={styles.split}>{!isLast && "/"}</span>
          </Fragment>
        )
      })}
    </div>
  )
}
