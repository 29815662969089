import { useCallback, useEffect, useState } from "react"

export function useFetchHook<T = {}, P = {}>(
  method: (params: P) => Promise<Response>,
  {
    params,
    defaultValue,
  }: {
    params?: P
    defaultValue?: T
  },
) {
  const [data, setData] = useState<T>(defaultValue as T)

  const init = useCallback(async () => {
    const res = await method(params as P)
    const json = await res.json()
    setData(json)
  }, [method, params])

  useEffect(() => {
    init()
  }, [init, method, params])

  return { data, reload: init }
}
