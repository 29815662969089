import { useState } from "react"
import {
  DeleteIcon,
  EditIcon,
  IconButton,
} from "../../../components/button/IconButton"
import { Sidepanel } from "../../../components/sidepanel/Sidepanel"
import { Project } from "../../../../types"
import { Input } from "../../../components/form/input"
import { Button } from "../../../components/button/Button"
import { toast } from "react-toastify"
import { updateProject } from "../../../fetch/projects"

export const EditProject = ({
  project,
  onSave,
}: {
  project: Project
  onSave?: () => void
}) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(project.name)
  const [code, setCode] = useState(project.code)
  const [errors, setErrors] = useState<Record<string, boolean>>({})

  const handleArchive = async () => {
    const y = window.confirm("Vai tiešām vēlieties arhivēt šo projektu?")

    if (y) {
      try {
        const re = await updateProject(project.id, { status: 6 })
        if (!re.ok) {
          throw new Error("")
        }

        // const json = await re.json()
        // if (json.error) {
        //   throw new Error("")
        // }
        toast("Projekts veiksmīgi arhivēts!", { type: "success" })
        onSave?.()
      } catch (err) {
        toast("Neizdevās arhivēt uzdevumu, lūdzu mēģini vēlreiz!", {
          type: "error",
        })
      }
    }
  }
  const handleSave = async () => {
    const nextErr: Record<string, boolean> = {}
    if (!name) {
      nextErr.name = true
    }

    if (!code) {
      nextErr.code = true
    }
    if (Object.keys(nextErr).length) {
      setErrors(nextErr)
      toast("Nosaukums un kods ir obligāti lauki", { type: "error" })
      return
    }

    try {
      const re = await updateProject(project.id, { name, code })
      if (!re.ok) {
        throw new Error()
      }
      // const json = await re.json()
      // if (json.error) {
      //   throw new Error()
      // }
      toast("Projekts atjaunots!", { type: "success" })
      onSave?.()
    } catch (err) {
      toast("Neizdevās saglabāt, lūdzu mēģini vēlreiz", { type: "error" })
    }
  }

  return (
    <>
      <IconButton onClick={() => setOpen((curr) => !curr)}>
        {EditIcon}
      </IconButton>
      <Sidepanel onClose={() => setOpen(false)} open={open}>
        <div>
          <Input
            label="Projekts"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errors.name}
          />
          <Input
            label="Kods"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={errors.code}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <IconButton onClick={handleArchive}>{DeleteIcon}</IconButton>
            <div style={{ display: "flex", gap: 8 }}>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Atcelt
              </Button>
              <Button onClick={handleSave}>Saglabāt</Button>
            </div>
          </div>
        </div>
      </Sidepanel>
    </>
  )
}
