import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "wouter"
import { Project } from "../../../../types"
import { ChevronLeft } from "../../../components/icons/ChevronLeft"
import { StatusItem } from "../../../components/statusItem/StatusItem"
import { getUserProjectById } from "../../../fetch/projects"
import styles from "./project-view.module.css"

export const ProjectView = () => {
  const [, setLocation] = useLocation()
  const params = useParams()
  const [proj, setProj] = useState<Project>()
  const [data, setData] = useState<
    {
      title: string
      items: Project[]
    }[]
  >()

  useEffect(() => {
    if (params.id) {
      getUserProjectById(params.id).then(async (res) => {
        const json = await res.json()
        setProj(json.project)
        setData(json.sections)
      })
    }
  }, [params.id])

  return (
    <div>
      <div className={styles.title}>
        <Link to="/dashboard">
          <ChevronLeft />
        </Link>
        <span>{proj?.name}</span>
      </div>

      {data?.map((proj) =>
        proj.items.map((item) => (
          <StatusItem
            name={item.name}
            status={item.status}
            onClick={() =>
              setLocation(`/dashboard/project/${params.id}/${item.id}`)
            }
            subTitle={`${item.subCount} darbi`}
          />
        )),
      )}
    </div>
  )
}
