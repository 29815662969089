import classNames from "classnames"
import { Input } from "../../../components/form/input"
import styles from "./adminusers.module.css"
import { Button } from "../../../components/button/Button"
import { useState } from "react"
import { createUser } from "../../../fetch/users"
import { useLocation } from "wouter"

export const CreateUser = () => {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState(1)

  const handleCreateUser = async () => {
    setLoading(true)
    try {
      await createUser({ name, surname, email, role })
      setLocation("..")
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <div className={styles.root}>
      <h1 className={styles.addTitle}>Pievienot darbinieku</h1>
      <div className={styles.row}>
        <Input
          placeholder="Vārds"
          label="Vārds"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Uzvārds"
          label="Uzvārds"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
      </div>
      <div className={styles.row}>
        <Input
          placeholder="E-pasts"
          label="E-pasts"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
      </div>
      <div className={styles.row}>
        <Input
          label="Administrators"
          type="checkbox"
          checked={role === 2}
          onChange={(e) => setRole(e.target.checked ? 2 : 1)}
        />
      </div>
      <div className={classNames(styles.row, styles.submit)}>
        <Button disabled={loading} onClick={handleCreateUser}>
          Saglabāt
        </Button>
      </div>
    </div>
  )
}
