import { localFetch } from "./localFetch"

export const login = (body: { username: string; password: string }) => {
  return localFetch(`/login`, {
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const logout = () => {
  return localFetch("/logout")
}
