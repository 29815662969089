import { localFetch } from "./localFetch"

export const getOperations = () => {
  return localFetch(`/api/views/op_groups`)
}

export const getOpList = () => {
  return localFetch(`/api/op_groups`)
}

export const createOperation = (body: { name: string; parentId: number }) => {
  return localFetch(`/api/op_groups`, {
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const deleteOperation = (id: number) => {
  return localFetch(`/api/op_groups/${id}`, { method: "DELETE" })
}

export const updateOperation = (id: number, name: string) => {
  return localFetch(`/api/op_groups/${id}`, {
    method: "PUT",
    body: JSON.stringify({ name }),
  })
}
