import { SelectHTMLAttributes } from "react"
import styles from "./input.module.css"

type Option = { id: string | number | undefined; name: string }

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Option[]
  label?: string
}

export const Select = (props: Props) => {
  return (
    <div className={styles.inputWrap}>
      <label>
        {props.label && <span>{props.label}</span>}
        <select className={styles.input} {...props}>
          <option disabled value="0" />
          {props.options.map((opt) => (
            <option key={opt.id} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}
