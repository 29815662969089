import classNames from "classnames"
import "./tabs.css"
import { ReactNode } from "react"

type Props = {
  tabs: { id: string | number; title: ReactNode }[]
  active: string | number
  onTabChange: (id: string | number) => void
}

export const SimpleTabs = ({ tabs, active, onTabChange }: Props) => {
  return (
    <div className="tabs">
      {tabs.map((tab) => {
        return (
          <div
            className={classNames("tab", {
              active: tab.id === active,
            })}
            onClick={() => onTabChange(tab.id)}
          >
            {tab.title}
          </div>
        )
      })}
    </div>
  )
}
