import { ReactNode } from "react"
import "./table.css"

type Props = {
  columns: { id: string; title: string }[]
  rows: ({ id: string | number } & Record<any, ReactNode>)[]
  onRowClick?: (id: number | string) => void
}
export const Table = ({ columns, rows, onRowClick }: Props) => {
  return (
    <table>
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={col.id}>{col.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr
              key={i}
              onClick={() => onRowClick?.(row.id)}
              className={row.error ? "error-tr" : ""}
            >
              {columns.map((col) => (
                <td key={`${col.id}-${i}`}>{row[col.id]}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
