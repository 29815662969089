import { localFetch } from "./localFetch"

export const getDistricts = () => {
  return localFetch(`/api/districts`)
}

export const createDistrict = (body: {
  name: string
  workers?: number
  shift?: number
  concurrentShifts?: number
}) => {
  return localFetch(`/api/districts`, {
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const deleteDistrict = (id: number) => {
  return localFetch(`/api/districts/${id}`, { method: "DELETE" })
}

export const updateDistrict = (
  id: number,
  body: {
    name?: string
    workers?: number
    shift?: number
    concurrentShifts?: number
  }
) => {
  return localFetch(`/api/districts/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
  })
}
