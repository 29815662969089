import { createWatchable } from "../lib/state/createWatchable"

export const authState = createWatchable({
  isAuth: false,
})

export const userState = createWatchable<{
  username: string
  email: string
  coeficient: number
  createdAt: string
  id: number
  lastLoginAt: string
  name: string
  permissions: number
  role: string
  tenantId: number
  user: {
    email: string
    id: number
    name: string
    username: string
  }
}>({
  username: "",
  email: "",
  coeficient: 0,
  createdAt: "",
  id: 0,
  lastLoginAt: "",
  name: "",
  permissions: 0,
  role: "",
  tenantId: 0,
  user: {
    email: "",
    id: 0,
    name: "",
    username: "",
  },
})
