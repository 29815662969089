import { useEffect, useState } from "react"
import { Button } from "../../components/button/Button"
import { login } from "../../fetch/login"
import { getSelf } from "../../fetch/users"
import styles from "./login.module.css"
import { authState, userState } from "../../store/user"
import { useWatcher } from "../../lib/state/useWatcher"
import { toast } from "react-toastify"
import { Input } from "../../components/form/input"

export const Login = () => {
  const auth = useWatcher(authState)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    setError(false)
  }, [username, password])

  const initUser = () => {
    getSelf()
      .then(async (res) => {
        const json = await res.json()
        if (res.ok) {
          authState.update({ isAuth: true })
          userState.update(json)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const re = await login({ username, password })
      if (re && re.ok) {
        authState.update({ isAuth: true })
        initUser()
      } else {
        const { error } = await re.json()
        if (error) {
          setError(true)
          toast("Lietotājvārds vai parole nav pareizi", { type: "error" })
          return
        }
        throw new Error("")
      }
    } catch (err) {
      console.log(err)
      toast("Failed to login, please try again later", { type: "error" })
    }
  }

  useEffect(() => {
    if (auth.isAuth) {
      return
    }
    initUser()
    // on initial load, check myself
  }, [auth])

  if (loading) {
    return null
  }
  return (
    <div className={styles.root}>
      <img src="/assets/logo.svg" alt="productionline.io" />
      <form onSubmit={handleSubmit}>
        <div className={styles.box}>
          <div className={styles.inputWrap}>
            <Input
              placeholder="Lietotājvārds"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={error}
            />
          </div>
          <div className={styles.inputWrap}>
            <Input
              placeholder="Parole"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              error={error}
            />
          </div>

          <Button type="submit">Ienākt</Button>
        </div>
      </form>
    </div>
  )
}
