import './infoBlock.css'

type Props = {
  title: string
  description: string
}
export const InfoBlock = ({ title, description }: Props) => {
  return (
    <div className="info-block">
      <div className="title">{title}</div>
      <div className="sub">{description}</div>
    </div>
  )
}
