import { ComponentProps, useEffect, useState } from "react"
import { Table } from "../../../components/table/Table"
import { getUsers } from "../../../fetch/users"
import { Button } from "../../../components/button/Button"
import styles from "./adminusers.module.css"
import { useLocation } from "wouter"

const columns = [
  { title: "Darbinieks", id: "name" },
  { title: "Lietotājvārds", id: "username" },
  // { title: "Koeficients", id: "coeficient" },
  // { title: "Kļūdas", id: "errorCount" },
  { title: "Administrators", id: "isAdmin" },
  // { title: "Mainīt paroli", id: "changePasswordUrl" },
  // { title: "", id: "edit" },
]

type Row = ComponentProps<typeof Table>["rows"]

const prepareTableData = (data: Record<string, any>[]) => {
  const rows: ComponentProps<typeof Table>["rows"] = []
  data.forEach((row) => {
    const curr: Row[number] = { id: row.id }

    columns.forEach(({ title, id }) => {
      switch (id) {
        case "changePasswordUrl": {
          if (row[id]) {
            curr[id] = <a href={row[id]}>Kopēt saiti</a>
          } else {
            curr[id] = <a href="~/#">Izveidot Linku</a>
          }
          break
        }
        case "edit": {
          curr[id] = <a href="#">Labot</a>
          break
        }
        case "isAdmin": {
          curr[id] = row.role >= 2 ? "✅" : ""
          break
        }
        default: {
          curr[id] = row[id]
          break
        }
      }
    })
    rows.push(curr)
  })
  return rows
}

export const AdminUsers = () => {
  const [, setLocation] = useLocation()
  const [tableData, setTableData] = useState<
    ComponentProps<typeof Table>["rows"]
  >([])
  useEffect(() => {
    getUsers().then(async (d) => {
      const json = await d.json()
      setTableData(prepareTableData(json.items))
    })
  }, [])

  return (
    <>
      <div className={styles.addBtnWrap}>
        <Button onClick={() => setLocation("/create")}>
          Pievienot darbinieku
        </Button>
      </div>
      <Table columns={columns} rows={tableData} />
    </>
  )
}
