import { useEffect, useState } from "react"
import {
  createOperation,
  deleteOperation,
  getOperations,
  updateOperation,
} from "../../../fetch/operations"
import { Group } from "../../../../types"
import "./adminOperations.css"
import {
  DeleteIcon,
  EditIcon,
  IconButton,
} from "../../../components/button/IconButton"

export const AdminOperations = () => {
  const [groups, setGroups] = useState<Group[]>([])
  const [formData, setFormData] = useState<Record<number, string>>({})
  const [editRow, setEdit] = useState<undefined | number>()
  const [editValue, setEditValue] = useState<string | undefined>()
  const [expanded, setExpanded] = useState<undefined | number>(0)

  const init = () => {
    getOperations().then(async (res) => {
      const json = await res.json()
      if (json.groups) {
        setGroups(json.groups)
      }
    })
  }
  useEffect(() => {
    init()
  }, [])

  const handleFormChange = (id: number, name: string) => {
    setFormData({
      ...formData,
      [id]: name,
    })
  }

  const handleSubmit = (e: React.FormEvent, id: number) => {
    e.preventDefault()
    const body = {
      name: formData[id],
      parentId: id,
    }

    createOperation(body).then(init)
  }

  const handleEditSubmit = (e: React.FormEvent, id: number) => {
    e.preventDefault()

    if (editValue) {
      updateOperation(id, editValue).then(() => {
        init()
        setEditValue(undefined)
        setEdit(undefined)
      })
    }
  }

  const onDelete = (id: number) => {
    deleteOperation(id).then(init)
  }

  const onEdit = (id: number, value: string) => {
    setEditValue(value)
    setEdit(id)
  }
  return (
    <>
      {groups.map((group, i) => (
        <div key={i} className={expanded === i ? "expanded group" : "group"}>
          <div
            onClick={() => setExpanded(expanded === i ? undefined : i)}
            className="title"
          >
            {group.name}
          </div>
          <div className="group-items">
            {group.operations.map((operation, i) => (
              <div className="row" key={i}>
                <span className="row-title">
                  {editRow === operation.id ? (
                    <form onSubmit={(e) => handleEditSubmit(e, operation.id)}>
                      <input
                        onChange={(e) => setEditValue(e.target.value)}
                        value={editValue ?? ""}
                        name="workName"
                        className="add-new"
                        placeholder="Pievienot jaunu darbu"
                      />
                    </form>
                  ) : (
                    operation.name
                  )}
                </span>
                <div className="row-edit">
                  <IconButton
                    onClick={() => onEdit(operation.id, operation.name)}
                  >
                    {EditIcon}
                  </IconButton>
                  <IconButton onClick={() => onDelete(operation.id)}>
                    {DeleteIcon}
                  </IconButton>
                </div>
              </div>
            ))}
            <div className="row">
              <form onSubmit={(e) => handleSubmit(e, group.id)}>
                <input
                  onChange={(e) => handleFormChange(group.id, e.target.value)}
                  value={formData[group.id] ?? ""}
                  name="workName"
                  className="add-new"
                  placeholder="Pievienot jaunu darbu"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                />
              </form>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
