import { useEffect, useState } from "react"
import { Watchable } from "./Watchable"

export function useWatcher<T extends object>(item: Watchable<T>) {
  const [state, setState] = useState(item.obj)

  useEffect(() => {
    function onUpdate(obj: typeof item.obj) {
      setState(obj)
      console.log("update called", obj)
    }
    item.subscribe(onUpdate)

    return () => {
      item.unsubscribe(onUpdate)
    }
  }, [item])

  return state
}
