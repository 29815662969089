import { useEffect, useMemo, useState } from "react"
import { Link, useLocation, useParams } from "wouter"
import { Group, Project } from "../../../../types"
import { ChevronLeft } from "../../../components/icons/ChevronLeft"
import { StatusItem } from "../../../components/statusItem/StatusItem"
import { SimpleTabs } from "../../../components/tabs/SimpleTabs"
import { getUserProjectOperations } from "../../../fetch/projects"
import styles from "./project-view.module.css"
import { Status } from "../../../components/status/Status"

export const OpenProjectView = () => {
  const [, setLocation] = useLocation()
  const { projId, subId } = useParams()
  const [data, setProject] = useState<{
    project: Project
    groups: (Group & { id: number })[]
  }>()
  const [active, setActive] = useState(0)

  useEffect(() => {
    if (projId && subId) {
      getUserProjectOperations(projId, subId).then(async (res) => {
        const proj = await res.json()
        setProject({
          project: proj.project,
          groups: proj.groups.map((g: Group, i: number) => ({ ...g, id: i })),
        })
      })
    }
  }, [projId, subId])

  const activeGroup: Group | undefined = useMemo(() => {
    return data?.groups[active]
  }, [active, data?.groups])
  return (
    <div>
      <div className={styles.title}>
        <Link to={`/dashboard/project/${projId}`}>
          <ChevronLeft />
        </Link>
        <span>{data?.project?.name}</span>
      </div>

      <div className={styles.tabsWrap}>
        <SimpleTabs
          tabs={
            data?.groups.map((g) => ({
              id: g.id,
              title: (
                <>
                  <Status status={g.status} /> {g.name}
                </>
              ),
            })) ?? []
          }
          active={active}
          onTabChange={(tab) => setActive(tab as number)}
        />
      </div>

      {activeGroup?.operations.map((item) => (
        <StatusItem
          name={item.name}
          status={item.status}
          onClick={() => {
            setLocation(`/dashboard/project/${projId}/${subId}/${item.id}`)
          }}
          subTitle={
            <>
              <span
                className={
                  item.status === 3 && item.unitsPredicted > item.unitsUsed
                    ? styles.error
                    : ""
                }
              >
                {item.unitsUsed} / {item.unitsPredicted}
              </span>{" "}
              Vienības
            </>
          }
        />
      ))}
    </div>
  )
}
