import { HeaderMenu } from "./HeaderMenu/HeaderMenu"
import "./header.css"
import { Link } from "wouter"

export const Header = ({ hideMenu }: { hideMenu?: boolean }) => {
  return (
    <div className="header">
      <Link to="/dashboard">
        <img src="/assets/logo.svg" alt="ProductionLine logo" />
      </Link>
      {!hideMenu && <HeaderMenu />}
    </div>
  )
}
