import { ButtonHTMLAttributes, PropsWithChildren } from "react"
import "./button.css"

export const EditIcon = <img src="/assets/edit.svg" alt="edit" />
export const DeleteIcon = <img src="/assets/delete.svg" alt="edit" />
export const PlusIcon = <img src="/assets/plus.svg" alt="plus" />
export const UserIcon = <img src="/assets/user.svg" alt="user" />

export const IconButton = ({
  children,
  ...props
}: PropsWithChildren & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button {...props} className="btn-icon">
      {children}
    </button>
  )
}
