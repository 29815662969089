type Subscriber<T> = (obj: T) => void

export class Watchable<T extends Object> {
  obj: T
  constructor(obj: T) {
    this.obj = { ...obj }
  }

  get() {
    return this.obj
  }

  update(newObj: T) {
    this.obj = { ...newObj }
    this.emit()
  }

  updatePartial(partial: Partial<T>) {
    this.obj = { ...this.obj, ...partial }
    this.emit()
  }

  addToArr<K extends keyof T>(p: K, arr: (typeof this.obj)[K]) {
    const entry = this.obj[p]
    if (Array.isArray(entry) && Array.isArray(arr)) {
      this.obj = {
        ...this.obj,
        [p]: [...entry, ...arr],
      }
    }
    this.emit()
  }

  emit() {
    this.subscribers.forEach((subscriber) => subscriber(this.obj))
  }

  subscribers: Subscriber<T>[] = []

  subscribe(subscriber: Subscriber<T>) {
    this.subscribers.push(subscriber)
  }

  unsubscribe(subscriber: Subscriber<T>) {
    this.subscribers = this.subscribers.filter((sub) => sub !== subscriber)
  }
}
