import { ComponentProps, useEffect, useMemo, useState } from "react"
import { InfoBlock } from "../../../components/infoBlock/InfoBlock"
import { InfoBlockWrap } from "../../../components/infoBlock/InfoBlockWrap"
import { Table } from "../../../components/table/Table"
import { Link, Route, Switch, useLocation } from "wouter"
import { IndividualProject } from "./IndividualProject"
import { getProjects } from "../../../fetch/projects"
import { Status } from "../../../components/status/Status"
import { isBitus } from "../../../../config/urls"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"
import { Filters } from "../AdminTasks/Filters"

const columns = [
  { title: "Status", id: "status" },
  { title: "Normatīvi", id: "difficulty" },
  { title: isBitus ? "Sērija" : "Projekts", id: "name" },
  { title: isBitus ? "Tirgi" : "Apakšprojekti", id: "subprojectCount" },
  { title: "Paredzētais laiks", id: "timePredicted" },
  { title: "Patērētais laiks", id: "timeUsed" },
  { title: isBitus ? "Paredzētas" : "Prognozētās", id: "unitsPredicted" },
  { title: "Zaudējumi", id: "salaryDiff" },
  { title: "Summa", id: "salary" },
  { title: "Vienības", id: "unitsUsed" },
]

type Row = ComponentProps<typeof Table>["rows"]

const prepareTableData = (data: Record<string, any>[]) => {
  const rows: ComponentProps<typeof Table>["rows"] = []
  data.forEach((row) => {
    const curr: Row[number] = { id: row.id }

    columns.forEach(({ id }) => {
      const value = row[id]
      switch (id) {
        case "status": {
          curr[id] = (
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <Status status={row.status} />
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                {row.progress}%
              </span>
            </div>
          )
          break
        }
        case "difficulty": {
          curr[id] = (
            <span
              style={{
                fontSize: 20,
                fontWeight: 500,
                color: row.difficulty > 100 ? "var(--error)" : undefined,
              }}
            >
              {row.difficulty}%
            </span>
          )
          break
        }
        case "name": {
          curr[id] = value
          break
        }
        case "subprojectCount": {
          curr[id] = (
            <Link to={`/${row.id}`}>
              {value} {isBitus ? "Iecirkņi" : "Apakšprojekti"}
            </Link>
          )
          break
        }
        case "salary": {
          const v = row["salaryUsed"]
          curr[id] = v ? `${v / 100}€` : "-"
          break
        }

        case "timePredicted":
        case "timeUsed": {
          curr[id] = value ? `${Math.floor(value / 60)}h ${value % 60}min` : "-"
          break
        }
        case "salaryDiff": {
          const v = row["salaryDiff"]
          const val = v ? `${v / 100}€` : "-"
          if (v < 0) {
            curr.error = true
          }
          curr[id] = (
            <span style={{ color: v < 0 ? "var(--error)" : "" }}>{val}</span>
          )
          break
        }
        case "unitsPredicted":
        case "unitsUsed": {
          curr[id] = value
          break
        }
      }
    })
    rows.push(curr)
  })
  return rows
}

export const AdminProjects = () => {
  const [, setLocation] = useLocation()
  const [filters, setFilters] = useState<Record<string, any>>({})
  const [data, setData] = useState<ComponentProps<typeof InfoBlock>[]>([])
  const {
    settings: { features },
  } = useWatcher(tenantState)

  const [tableData, setTableData] = useState<
    ComponentProps<typeof Table>["rows"]
  >([])

  useEffect(() => {
    getProjects(filters).then(async (d) => {
      const json = await d.json()
      setData(json.info)
      setTableData(prepareTableData(json.items))
    })
  }, [filters])

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => {
      if (col.id === "timeUsed") {
        return features[TenantFeature.Time]
      }

      if (col.id === "unitsUsed") {
        return features[TenantFeature.Units]
      }

      if (col.id === "salary" || col.id === "salaryDiff") {
        return features[TenantFeature.Salary]
      }

      if (col.id === "difficulty") {
        return features[TenantFeature.Normatives]
      }

      return true
    })
  }, [features])
  return (
    <Switch>
      <Route path="/:id" component={IndividualProject} nest />
      <Route path="/">
        <>
          <Filters
            onFiltersChange={setFilters}
            availableFilters={["from", "to", "statuses"]}
            show={true}
          />
          <InfoBlockWrap>
            {data.map((block, i) => (
              <InfoBlock {...block} key={i} />
            ))}
          </InfoBlockWrap>
          <Table
            columns={filteredColumns}
            rows={tableData}
            onRowClick={(id) => setLocation(`/${id}`)}
          />
        </>
      </Route>
    </Switch>
  )
}
