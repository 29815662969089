import { useEffect, useState, Fragment } from "react"
import { Button } from "../../../components/button/Button"
import {
  DeleteIcon,
  IconButton,
  PlusIcon,
} from "../../../components/button/IconButton"
import { Input } from "../../../components/form/input"
import { getOpList } from "../../../fetch/operations"
import { importTask } from "../../../fetch/tasks"
import styles from "./admin-import.module.css"
import { isBitus } from "../../../../config/urls"
import { useLocation } from "wouter"

/**
 *
 *
 * {
    "project": "Nosaukums 144",
    "code": "346144",
    "items": [
      {
        "project": "ewger",
        "code": "436546",
        "operations": [
            {
                "opGroup": {
                    "id": 1
                },
                "items": [
                    {
                        "opGroup": {
                            "id": 5
                        },
                        "sum": 23,
                        "units": 123,
                        "time": 123
                    }
                ]
            },
            {
                "opGroup": {
                    "id": 2
                },
                "items": [
                    {
                        "opGroup": {
                            "name": "New op children"
                        },
                        "units": 33
                    },
                    {
                        "opGroup": {
                            "id": 8
                        },
                        "sum": 234,
                        "time": 12
                    }
                ]
            }
        ]
      }
    ]
}
*/
type ImportType = {
  project: string
  code: string
  items: {
    project: string
    code: string
    operations: {
      opGroup: string
      items: {
        opGroup: string
        sum: number
        units: number
        normative: number
      }[]
    }[]
  }[]
}

const KEYS = ["opGroup", "sum", "units", "normative"]
type OpsSelectType = Record<
  string,
  {
    id: number
    name: string
    operations: { name: string; id: number; parentId: number }[]
  }
>

const Labels: Record<(typeof KEYS)[number], string> = {
  opGroup: "Darbs",
  sum: "Summa",
  units: "Vienības",
  normative: "Normatīvs",
}
export const AdminImport = () => {
  const [, setLocation] = useLocation()
  const [ops, setOps] = useState<OpsSelectType>({})
  const [project, setProject] = useState<ImportType>({
    project: "",
    code: "",
    items: [
      {
        project: "",
        code: "",
        operations: [
          {
            opGroup: "",
            items: [{ opGroup: "", sum: 0, units: 0, normative: 0 }],
          },
        ],
      },
    ],
  })

  const handleCsv = (csv: string) => {
    const [, ...lines] = csv.split("\n")
    const items: any[] = []
    let item: null | any = null
    let operation: null | any = null
    lines.forEach((line) => {
      const [proj, op, sub, , amount, time] = line.split(/,|;/)
      if (proj && op) {
        item = {
          project: proj,
          code: "",
          operations: [],
        }
        items.push(item)
      }
      if (op || sub) {
        if (item) {
          if (op) {
            operation = {
              opGroup: op,
              items: [],
            }
            item.operations.push(operation)
          }
          if (sub) {
            const units = +amount
            if (operation) {
              operation.items.push({
                opGroup: sub,
                sum: 0,
                units,
                normative: +time,
              })
            }
          }
        }
      }
    })
    setProject({
      ...project,
      items,
    })
  }

  useEffect(() => {
    getOpList().then(async (res) => {
      const json = await res.json()
      const groups: any = {}

      json.forEach((op: any) => {
        if (!op.parentId) {
          groups[op.id] = {
            ...op,
            operations: [],
          }
        }
      })

      json.forEach((op: any) => {
        if (op.parentId && groups[op.parentId]) {
          groups[op.parentId].operations.push(op)
        }
      })
      setOps(groups)
    })
  }, [])

  const handleSubprojectFieldChange = (
    pidx: number,
    key: "project" | "code",
    value: string,
  ) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        ...project.items[pidx],
        [key]: value,
      }),
    })
  }

  const handleAddSubproject = (pidx: number) => {
    setProject({
      ...project,
      items: project.items.toSpliced(pidx, 0, {
        project: "",
        code: "",
        operations: [
          {
            opGroup: "",
            items: [{ opGroup: "", units: 0, normative: 0, sum: 0 }],
          },
        ],
      }),
    })
  }

  const handleRemoveSubproject = (pidx: number) => {
    setProject({
      ...project,
      items: project.items.toSpliced(pidx, 1),
    })
  }
  const handleAddOperationGroup = (pidx: number, idx: number) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        project: project.items[pidx].project,
        code: project.items[pidx].code,
        operations: project.items[pidx].operations.toSpliced(idx, 0, {
          opGroup: "",
          items: [{ opGroup: "", units: 0, normative: 0, sum: 0 }],
        }),
      }),
    })
  }

  const handleRemoveOperationGroup = (pidx: number, idx: number) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        project: project.items[pidx].project,
        code: project.items[pidx].code,
        operations: project.items[pidx].operations.toSpliced(idx, 1),
      }),
    })
  }
  const handleNameChange = (pidx: number, opIdx: number, name: string) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        project: project.items[pidx].project,
        code: project.items[pidx].code,
        operations: project.items[pidx].operations.with(opIdx, {
          opGroup: name,
          items: project.items[pidx].operations[opIdx].items,
        }),
      }),
    })
  }

  const handleOperationChange = (
    pidx: number,
    opIdx: number,
    itemIdx: number,
    key: string,
    value: number | { id: number } | string,
  ) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        project: project.items[pidx].project,
        code: project.items[pidx].code,
        operations: project.items[pidx].operations.with(opIdx, {
          opGroup: project.items[pidx].operations[opIdx].opGroup,
          items: project.items[pidx].operations[opIdx].items.with(itemIdx, {
            ...project.items[pidx].operations[opIdx].items[itemIdx],
            [key]: value,
          }),
        }),
      }),
    })
  }

  const addOperationRow = (pidx: number, opIdx: number, idx: number) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        project: project.items[pidx].project,
        code: project.items[pidx].code,
        operations: project.items[pidx].operations.with(opIdx, {
          opGroup: project.items[pidx].operations[opIdx].opGroup,
          items: project.items[pidx].operations[opIdx].items.toSpliced(idx, 0, {
            opGroup: "",
            sum: 0,
            units: 0,
            normative: 0,
          }),
        }),
      }),
    })
  }

  const removeOperationRow = (pidx: number, opIdx: number, idx: number) => {
    setProject({
      ...project,
      items: project.items.with(pidx, {
        project: project.items[pidx].project,
        code: project.items[pidx].code,
        operations: project.items[pidx].operations.with(opIdx, {
          opGroup: project.items[pidx].operations[opIdx].opGroup,
          items: project.items[pidx].operations[opIdx].items.toSpliced(idx, 1),
        }),
      }),
    })
  }

  const handleImport = () => {
    importTask(project).then(async (res) => {
      const id = await res.json()
      if (typeof id === "object" && id.error) {
        return
      }
      setLocation(`/../projects/${id}`)
    })
  }

  const handleFileLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
    const csv = e.target.files?.[0]
    const fileReader = new FileReader()
    if (csv) {
      fileReader.readAsText(csv)
      fileReader.onload = (ev) => {
        if (ev.target?.result && typeof ev.target?.result === "string") {
          handleCsv(ev.target.result)
        }
      }
    }
  }

  return (
    <>
      {isBitus && (
        <div className={styles.projectRow}>
          <Input
            type="file"
            label="Izvēlēties failu"
            onChange={handleFileLoad}
            accept=".csv"
          />
        </div>
      )}
      <div className={styles.projectRow}>
        <div className={styles.projectWrap}>
          <Input
            label={isBitus ? "Sērija" : "Projekts"}
            value={project.project}
            onChange={(e) =>
              setProject({ ...project, project: e.target.value })
            }
            placeholder="Ievadīt"
          />
          <Input
            label="Kods"
            value={project.code}
            onChange={(e) => setProject({ ...project, code: e.target.value })}
            placeholder="Ievadīt"
          />
        </div>
      </div>
      {project.items.map((subproject, pidx) => (
        <Fragment key={`sub-project-${pidx}`}>
          <div className={styles.subprojectRow}>
            <div className={styles.subprojectWrap}>
              <div></div>
              <Input
                label={isBitus ? "Iecirknis" : "Apakšprojekts"}
                value={subproject.project}
                onChange={(e) =>
                  handleSubprojectFieldChange(pidx, "project", e.target.value)
                }
                placeholder="Ievadīt"
              />
              <Input
                label="Kods"
                value={subproject.code}
                onChange={(e) =>
                  handleSubprojectFieldChange(pidx, "code", e.target.value)
                }
                placeholder="Ievadīt"
              />
            </div>
            <div className={styles.actionRow}>
              <IconButton onClick={() => handleAddSubproject(pidx + 1)}>
                {PlusIcon}
              </IconButton>
              <IconButton
                disabled={project.items.length <= 1}
                onClick={() => handleRemoveSubproject(pidx)}
              >
                {DeleteIcon}
              </IconButton>
            </div>
          </div>
          {subproject.operations.map((operation, opIdx) => (
            <div
              className={styles.operationGroupRow}
              key={`sub-project-${pidx}-operation-group-${opIdx}`}
            >
              <div className={styles.operationGroupWrap}>
                {/*<Select
                  label={isBitus ? "Tirgus" : "Operāciju grupa"}
                  value={operation.opGroup.id}
                  onChange={(e) =>
                    handleNameChange(pidx, opIdx, +e.target.value)
                  }
                  options={processOpts}
                /> */}

                <Input
                  label={isBitus ? "Tirgus" : "Operāciju grupa"}
                  value={operation.opGroup}
                  onChange={(e) =>
                    handleNameChange(pidx, opIdx, e.target.value)
                  }
                />
                <div className={styles.tasks}>
                  {KEYS.map((key) => {
                    if (isBitus && key === "sum") return null
                    return (
                      <div
                        className={styles.tasksCol}
                        key={`sub-project-${pidx}-operation-group-${opIdx}-field-${key}`}
                      >
                        <label>
                          {isBitus && key === "opGroup"
                            ? "Detaļa"
                            : Labels[key]}
                        </label>
                        {operation.items.map((item, itemIdx) => {
                          return (
                            <div
                              key={`sub-project-${pidx}-operation-group-${opIdx}-field-${key}-item-${itemIdx}`}
                            >
                              {key === "opGroup" ? (
                                // <Select
                                //   value={item.opGroup.id}
                                //   onChange={(e) =>
                                //     handleOperationChange(
                                //       pidx,
                                //       opIdx,
                                //       itemIdx,
                                //       key,
                                //       { id: +e.target.value },
                                //     )
                                //   }
                                //   options={
                                //     ops[operation.opGroup.id]?.operations ?? []
                                //   }
                                // />
                                <Input
                                  value={item.opGroup}
                                  onChange={(e) =>
                                    handleOperationChange(
                                      pidx,
                                      opIdx,
                                      itemIdx,
                                      key,
                                      e.target.value,
                                    )
                                  }
                                />
                              ) : (
                                <Input
                                  // @ts-ignore
                                  value={item[key] ?? ""}
                                  onChange={(e) =>
                                    handleOperationChange(
                                      pidx,
                                      opIdx,
                                      itemIdx,
                                      key,
                                      +e.target.value,
                                    )
                                  }
                                />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}

                  <div className={styles.actions}>
                    {operation.items.map((_, idx) => (
                      <div
                        className={styles.actionsRow}
                        key={`sub-project-${pidx}-operation-group-${opIdx}-actions-${idx}`}
                      >
                        <IconButton
                          onClick={() => addOperationRow(pidx, opIdx, idx + 1)}
                        >
                          {PlusIcon}
                        </IconButton>
                        <IconButton
                          disabled={operation.items.length <= 1}
                          onClick={() => removeOperationRow(pidx, opIdx, idx)}
                        >
                          {DeleteIcon}
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.actionRow}>
                <IconButton
                  onClick={() => handleAddOperationGroup(pidx, opIdx + 1)}
                >
                  {PlusIcon}
                </IconButton>
                <IconButton
                  disabled={subproject.operations.length <= 1}
                  onClick={() => handleRemoveOperationGroup(pidx, opIdx)}
                >
                  {DeleteIcon}
                </IconButton>
              </div>
            </div>
          ))}

          <div style={{ height: 150 }} />
        </Fragment>
      ))}

      <div className={styles.saveAction}>
        <Button onClick={handleImport}>Saglabāt</Button>
      </div>
    </>
  )
}
