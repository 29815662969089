import { useEffect, useState } from "react"
import { deleteDistrict, getDistricts } from "../../../fetch/districts"
import { District } from "../../../../types"
import "./adminDistricts.css"
import {
  DeleteIcon,
  EditIcon,
  IconButton,
} from "../../../components/button/IconButton"
import { EditDistrict } from "./EditDistrict"
import { Button } from "../../../components/button/Button"

export const AdminDistricts = () => {
  const [districts, setDistricts] = useState<District[]>([])
  const [editRow, setEdit] = useState<undefined | Partial<District>>()

  const init = () => {
    getDistricts().then(async (res) => {
      const json = await res.json()
      if (json) {
        setDistricts(json)
      }
    })
  }
  useEffect(() => {
    init()
  }, [])

  const onDelete = (id: number) => {
    deleteDistrict(id).then(init)
  }

  const onEdit = (district: Partial<District>) => {
    setEdit({ ...district })
  }

  const onClose = (updated?: boolean) => {
    setEdit(undefined)
    updated && init()
  }

  function setEditValue<k extends keyof District>(
    field: k,
    value: District[k]
  ) {
    if (editRow != null) {
      setEdit({ ...editRow, [field]: value })
    }
  }

  return (
    <>
      <div className="districts-add">
        <Button
          onClick={() =>
            setEdit({
              name: "",
              shift: 8,
              workers: 1,
              concurrentShifts: 1,
            })
          }
        >
          Pievienot iecirkni
        </Button>
      </div>
      <div className="group-districts">
        <div className="group-districts-items">
          {districts.map((district, i) => (
            <div className="row-district" key={`district-${district.id}-${i}`}>
              <span className="row-district-title">{district.name}</span>
              <span className="row-district-info">
                {district.workers} Darbiniek
                {district.workers % 10 == 1 ? "s" : "i"}
              </span>
              <div className="row-district-edit">
                <IconButton onClick={() => onEdit(district)}>
                  {EditIcon}
                </IconButton>
                <IconButton onClick={() => onDelete(district.id)}>
                  {DeleteIcon}
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </div>
      <EditDistrict
        district={editRow}
        onClose={onClose}
        setEditValue={setEditValue}
      />
    </>
  )
}
