import { ReactNode } from "react"
import { Project } from "../../../types"
import { Status } from "../status/Status"
import styles from "./status-item.module.css"

type Props = {
  status?: Project["status"]
  name: string
  subTitle?: ReactNode
  onClick: () => void
}
export const StatusItem = ({ status, name, subTitle, onClick }: Props) => {
  return (
    <div className={styles.item} onClick={onClick}>
      <Status status={status} />
      <div>
        <div className={styles.title}>{name}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
    </div>
  )
}
