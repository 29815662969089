import { Button } from "../../../components/button/Button"
import { Input } from "../../../components/form/input"
import { logout } from "../../../fetch/login"
import { useWatcher } from "../../../lib/state/useWatcher"
import { userState } from "../../../store/user"
import styles from "./userview.module.css"

export const UserView = () => {
  const me = useWatcher(userState)
  const [name, surname] = me.name.split(" ")

  const handleLogout = async () => {
    await logout()
    window.location.reload()
  }
  return (
    <div className={styles.root}>
      <h1>{me.name}</h1>

      <Input disabled value={name} label="Vārds" />
      <Input disabled value={surname} label="Uzvārds" />
      <Input disabled value={me.email} label="E-pasts" />
      <Button onClick={handleLogout} variant="inline">
        Iziet no sistēmas
      </Button>
    </div>
  )
}
