import { ComponentProps, useEffect, useState } from "react"
import { InfoBlock } from "../../../components/infoBlock/InfoBlock"
import { InfoBlockWrap } from "../../../components/infoBlock/InfoBlockWrap"
import { Table } from "../../../components/table/Table"
import { getErrors } from "../../../fetch/errors"
import { formatTime } from "../../../lib/formatTime"

const columns = [
  { title: "Pieteicējs", id: "issuer" },
  { title: "Datums", id: "createdAt" },
  { title: "Projekts", id: "project" },
  { title: "Process", id: "operation" },
  { title: "Iesaistītie", id: "workers" },
  { title: "Komentārs", id: "comment" },
  { title: "Tips", id: "fixable" },
  { title: "Zaudējumi", id: "expenseLoses" },
  { title: "", id: "status" },
]

type Row = ComponentProps<typeof Table>["rows"]

const prepareTableData = (data: Record<string, any>[]) => {
  const rows: ComponentProps<typeof Table>["rows"] = []
  data.forEach((row) => {
    const curr: Row[number] = { id: row.id }

    columns.forEach(({ id }) => {
      const value = row[id]
      switch (id) {
        case "issuer": {
          curr[id] = value?.name
          break
        }
        case "createdAt": {
          curr[id] = value ? formatTime(value) : "-"
          break
        }
        case "operation":
        case "project": {
          curr[id] = value?.name
          break
        }
        case "workers": {
          const title =
            value?.length > 1 ? `${value.length} Darbinieki` : value?.[0]?.name
          curr[id] = title
          break
        }
        case "comment": {
          curr[id] = value
          break
        }
        case "fixable": {
          curr[id] = value ? "Labojams" : "Nav labojams"
          break
        }

        case "expenseLoses": {
          curr[id] = value ? `${value / 100}€` : "-"
          break
        }
        case "status": {
          /*
          .status: Enum {
            TaskErrorStatus_Created  = 0
            TaskErrorStatus_Approved = 1
            TaskErrorStatus_Declined = 2
          }*/
          switch (value) {
            case 0: {
              curr.error = true
              curr[id] = <div>Apstiprināt / Noraidīt</div>
              break
            }
            case 1: {
              curr.error = true
              curr[id] = "Apstiprināts"
              break
            }
            case 2: {
              curr[id] = "Noraidīts"
              break
            }
          }
        }
      }
    })
    rows.push(curr)
  })
  return rows
}

export const AdminErrors = () => {
  const [data, setData] = useState<ComponentProps<typeof InfoBlock>[]>([])
  const [tableData, setTableData] = useState<
    ComponentProps<typeof Table>["rows"]
  >([])
  useEffect(() => {
    getErrors().then(async (d) => {
      const json = await d.json()
      setData(json.info)
      setTableData(prepareTableData(json.items))
    })
  }, [])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 150,
      }}
    >
      <p className="title" style={{ fontSize: 20 }}>
        Šobrīd nav kļūdu...
      </p>
    </div>
  )

  return (
    <>
      <InfoBlockWrap>
        {data.map((block, i) => (
          <InfoBlock {...block} key={i} />
        ))}
      </InfoBlockWrap>
      <Table columns={columns} rows={tableData} />
    </>
  )
}
