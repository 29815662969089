import { PropsWithChildren } from "react"
import styles from "./sidepanel.module.css"
import classNames from "classnames"
import { Button } from "../button/Button"

export const Sidepanel = ({
  children,
  open,
  onClose,
}: PropsWithChildren<{ open: boolean; onClose?: () => void }>) => {
  return (
    <div
      className={classNames(styles.sidepanel, open && styles.open)}
      onClick={(e) => e.stopPropagation()}
    >
      {onClose && (
        <div className={styles.closeButton}>
          <Button variant="inline" onClick={() => onClose()}>
            Aizvērt
          </Button>
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  )
}
